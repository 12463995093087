import { Button } from "@material-ui/core"
import { useLayoutContext } from "layout/LayoutContext"
import { toggleContactPopUp } from "layout/layoutSlice"
import router from "next/router"
import { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useTypedSelector } from "store"
import styled, { css } from "styled-components"
import NextLink from "./NextLink"
import { useScrollSections } from "./ScrollSection"
import {
  trackContentUsClick,
  trackCoordinate,
  trackPurchaseClick,
} from "./analytics"

const StyledMainContainer = styled.div<{
  $paddingTop: string
  $isTopOfPage: boolean
  $isSafetyPollution: boolean
  $align: string
  $articlePage: boolean
  $refer: boolean
  $fixed: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: 0;
  padding-top: ${(p) => (p.$paddingTop ? p.$paddingTop : "32px")};
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  z-index: 4;
  ${(p) => p.theme.breakpoints.down("sm")} {
    ${(p) =>
      p.$refer && !p.$fixed
        ? css`
            display: flex;
            flex-direction: column;
            width: 100%;
            bottom: 0;
            padding: 0px;
            position: relative;
            top: -10px;
          `
        : !p.$articlePage
        ? css`
            position: fixed;

            background-image: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0),
              #fff 64%
            );
          `
        : css`
            display: flex;
            flex-direction: column;
            width: 100%;
            bottom: 0;
            padding-top: 20px;
            padding-bottom: 60px;
            padding-right: 0px;
            padding-left: 0px;
          `}
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    align-items: ${(p) => (p.$align ? p.$align : "center")};
    padding-bottom: 30px;
    bottom: 0;
  }
`
const StyledButtonWrapper = styled.div<{
  $isTopOfPage: boolean
}>`
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  justify-content: space-around;
  grid-gap: 15px;
  ${(p) => p.theme.breakpoints.up("md")} {
    justify-content: space-between;
  }
`
export const BuyOnlineButton = styled(Button)`
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  color: ${(p) => p.theme.colors.white};
  background-image: linear-gradient(
    to right,
    ${(p) => p.theme.colors.mainBlue},
    ${(p) => p.theme.colors.mainPurple}
  );
`
const TestDriveButton = styled(Button)`
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  height: 50px;

  border: 2px solid ${(p) => p.theme.colors.darkNavyBlue};
  background-color: ${(p) => p.theme.colors.white};
`

type BottomButtonsProps = {
  align?: string
  paddingTop?: string
  articlePage?: boolean
  className?: string
  refer?: boolean
  fixed?: boolean
}
const BottomButtons = ({
  align,
  paddingTop,
  articlePage,
  className,
  refer,
  fixed,
}: BottomButtonsProps) => {
  const dispatch = useAppDispatch()
  const { selected } = useScrollSections()
  const { purchaseModelId } = useTypedSelector((state) => state.layout)
  const [scrollY, setScrollY] = useState(0)
  const { isPurchaseEnabled } = useLayoutContext()

  const sendEvent = useCallback(() => {
    trackPurchaseClick({ tabName: selected })
  }, [selected])

  const handleScroll = useCallback(() => {
    setScrollY(window.pageYOffset)
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  const sendContentUsEvent = useCallback(() => {
    trackContentUsClick({ location: "body" })
  }, [])

  const handleBuyOnlineClick = useCallback(() => {
    if (purchaseModelId) {
      sendEvent()
      router.push(`/${purchaseModelId}`)
    } else {
      sendContentUsEvent()
      dispatch(toggleContactPopUp())
    }
  }, [purchaseModelId, sendEvent, sendContentUsEvent, dispatch])

  return (
    <StyledMainContainer
      $align={align}
      $isTopOfPage={scrollY <= 100}
      $isSafetyPollution={selected === "safety-pollution"}
      $paddingTop={paddingTop}
      $articlePage={articlePage}
      className={className}
      $refer={refer}
      $fixed={fixed}
    >
      <StyledButtonWrapper $isTopOfPage={scrollY === 0}>
        {purchaseModelId ? (
          <BuyOnlineButton id="buyOnline" onClick={handleBuyOnlineClick}>
            {isPurchaseEnabled ? "רכישה אונליין" : "עצב את ה GEELY שלך"}
          </BuyOnlineButton>
        ) : (
          <BuyOnlineButton onClick={() => dispatch(toggleContactPopUp())}>
            צור קשר
          </BuyOnlineButton>
        )}
        {refer && (
          <NextLink href={`/driveTest`} passHref>
            <TestDriveButton
              onClick={() => trackCoordinate("לתיאום נסיעת חוויה")}
            >
              לתיאום נסיעת חוויה
            </TestDriveButton>
          </NextLink>
        )}
      </StyledButtonWrapper>
    </StyledMainContainer>
  )
}

export default BottomButtons
