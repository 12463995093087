import { createContext, RefObject } from "react"

const DEFAULT_CONTEXT = {
  selected: "",
  refs: {},
  scrollTo: () => {},
  registerRef: () => null,
}

export type RefsRegister = {
  [x: string]: RefObject<HTMLElement | null>
}

export type ScrollContextType = {
  registerRef: (id: string) => RefObject<HTMLElement | null> | null
  scrollTo: (section: string) => void
  refs: RefsRegister
  selected: string
}

export const ScrollContext = createContext<ScrollContextType>(DEFAULT_CONTEXT)

export const { Consumer, Provider } = ScrollContext
