import { useContext } from "react"
import { ScrollContext } from "./context"

export const useScrollSection = (id: string) => {
  const { scrollTo, selected: selectedSection } = useContext(ScrollContext)
  const onClick = () => scrollTo(id)
  const selected = selectedSection === id

  return { onClick, selected }
}

export const useScrollSections = () => {
  const { scrollTo, selected, refs } = useContext(ScrollContext)
  const sections = Object.keys(refs).map((id) => ({
    id,
    title: refs[id].current?.dataset.title,
    onClick: () => scrollTo(id),
    selected: selected === id,
    activeColor: refs[id].current?.dataset.activeColor,
    inactiveColor: refs[id].current?.dataset.inactiveColor,
  }))

  return { selected, sections }
}
