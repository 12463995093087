import { FC, useContext, useEffect, useMemo } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { ScrollContext } from "./context"

/* eslint-disable react-hooks/exhaustive-deps */

const StyledSection = styled.section`
  position: relative;
`

type Props = {
  id: string
  title: string
  activeColor: string
  inactiveColor: string
  aboveTheFold?: boolean
  children: React.ReactNode
} & React.HTMLProps<HTMLButtonElement>

const Section: FC<Props> = ({
  id,
  title,
  children,
  activeColor,
  inactiveColor,
  ...rest
}) => {
  const { registerRef } = useContext(ScrollContext)
  const ref = useMemo(() => registerRef(id), [id])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "100px",
  })
  useEffect(() => {
    if (ref.current) {
      inViewRef(ref.current)
    }
  }, [ref.current, inViewRef])

  return (
    <StyledSection
      {...rest}
      ref={ref}
      as={null}
      data-title={title}
      data-active-color={activeColor}
      data-inactive-color={inactiveColor}
    >
      {/* {inView || aboveTheFold ? children : null} */}
      {children}
    </StyledSection>
  )
}

export default Section
