export const seoKeys = {
  NotFound: "notFound",
  Homepage: "homePage",
  model: "model_",
  article: "article_",
  agencies: "agencies",
  calculator: "calculator",
  faq: "faq",
  stations: "stations",
  about: "about",
  articles: "articles",
  files: "files",
  videos: "videos",
  leasing: "leasing",
  finance: "finance",
  services: "services",
  careers: "careers",
  driveTest: "driveTest",
  recall: "recall",
  mygeelyapp: "mygeelyapp",
  scheduleatreatment: "scheduleatreatment",
}
export type SeoKey = keyof typeof seoKeys

for (const key in seoKeys) {
  seoKeys[key] = encodeURIComponent(
    seoKeys[key]
      .replace(/\/\[.+/, "")
      .replace("/", "")
      .replace(/-/g, " ")
  )
}
