import { Button } from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import { extractData, getBannerGetById, MainBanner } from "api"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useIsDesktop } from "./MediaQueries"
import NextLink from "./NextLink"
import { seoKeys } from "./SeoMetaData"

export const getBannerByKey = async (key: string) => {
  let bannerSeo: MainBanner | null = null
  try {
    bannerSeo = await getBannerGetById(key).then(extractData)
  } catch (error) {
    bannerSeo = null
  }
  return bannerSeo
}

const StyledImageSeoBannerWrapper = styled.div<{ $isHomepage?: boolean }>`
  height: auto;
  position: relative;
  margin-top: ${(props) => (props.$isHomepage ? "69px" : "0")};
  margin-bottom: ${(props) => props.$isHomepage && "-69px"};
  min-height: 100px;
  z-index: ${(props) => props.$isHomepage && "1"};

  ${(p) => p.theme.breakpoints.up("md")} {
    margin-top: ${(props) => (props.$isHomepage ? "79px" : "0")};
    margin-bottom: ${(props) => props.$isHomepage && "-79px"};
  }
  > a {
    z-index: 1;
  }
`
const StyledBannerSeoTermsLink = styled(Button).attrs({ component: "div" })<{
  $termsColor?: string
}>`
  text-decoration: underline;
  color: ${(p) => p.$termsColor};
  font-size: 16px;
  border: none;
  font-weight: normal;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: transparent;
  }
`

const StyledCloseButton = styled(Button)`
  opacity: 0.85;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(p) => p.theme.colors.white};
  position: absolute;
  top: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  z-index: 2;
  min-width: 0;
`
const StyledImage = styled.img`
  display: block;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
`

type BannerSeoDataProps = {
  bannerSeoData: MainBanner
  pageName: string
}

export const SeoBanner = ({ bannerSeoData, pageName }: BannerSeoDataProps) => {
  const [isStored, setIsStored] = useState<boolean | null>(null)
  const bannerKey = `${pageName}:${bannerSeoData?.id}`
  const isDesktop = useIsDesktop()

  useEffect(() => {
    if (localStorage?.getItem(bannerKey)) {
      setIsStored(true)
    } else {
      setIsStored(false)
    }
  }, [bannerKey])

  const goToLink = (e) => {
    e?.preventDefault()
    e?.stopPropagation()
    window.open(
      bannerSeoData?.termsFile.imageUrl,
      `${bannerSeoData?.termsIsNewTab === true ? `_blank` : `_self`}`
    )
  }

  const closeAndStore = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setIsStored(true)
    localStorage?.setItem(bannerKey, "true")
  }
  const image = isDesktop
    ? bannerSeoData?.imageDesktop
    : bannerSeoData?.imageMobile

  if (!bannerSeoData) {
    return null
  }
  return (
    !isStored &&
    isStored !== null && (
      <StyledImageSeoBannerWrapper $isHomepage={pageName === seoKeys.Homepage}>
        <StyledCloseButton
          onClick={(e) => closeAndStore(e)}
          autoFocus
          aria-label="סגירה"
        >
          <CloseIcon />
        </StyledCloseButton>
        <NextLink href={bannerSeoData?.deepLink}>
          <StyledImage
            src={image?.imageUrl}
            alt={image?.alt}
            width="100%"
            height="auto"
          />

          {bannerSeoData?.addTerms && bannerSeoData?.termsFile?.imageUrl && (
            <StyledBannerSeoTermsLink
              onClick={goToLink}
              $termsColor={bannerSeoData?.termsColor}
            >
              {bannerSeoData?.termsText ?? "בכפוף לתקנון"}
            </StyledBannerSeoTermsLink>
          )}
        </NextLink>
      </StyledImageSeoBannerWrapper>
    )
  )
}
