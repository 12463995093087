import Image from "next/image"
import React, { memo } from "react"

const getSizes = (size: string[] | string | null): string | undefined => {
  if (!size) return undefined
  let sizes: string
  switch (typeof size) {
    case "string":
      sizes = size
      break
    default:
      sizes =
        size.length > 1 ? `(min-width: 768px) ${size[1]}, ${size[0]}` : size[0]
  }
  return sizes
}

type NextImageProps = {
  image: string
  alt: string
  /**
   * Represents the size of the image in the screen width in vw or in px
   * if Array, first value for mobile and second value desktop
   *
   */
  imageSizes?: string[] | string
  cover?: boolean
  priority?: boolean
  objectPosition?: string
} & React.HTMLProps<HTMLImageElement>

const NextImage = ({
  image,
  alt,
  imageSizes,
  cover = true,
  className,
  priority = false,
  objectPosition,
}: NextImageProps) => {
  const objectFit = cover ? "cover" : "contain"
  if (!image) {
    return null
  }
  return (
    <Image
      src={image}
      sizes={getSizes(imageSizes || null)}
      alt={alt || ""}
      className={className}
      layout="fill"
      objectFit={objectFit}
      priority={priority}
      objectPosition={objectPosition}
    />
  )
}

export default memo(NextImage)
