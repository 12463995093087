import { createRef, ReactNode, useEffect, useMemo, useState } from "react"
import smoothscroll from "smoothscroll-polyfill"
import { Provider, RefsRegister } from "./context"
import { debounce } from "./utils"

/* eslint-disable react-hooks/exhaustive-deps */

type Props = {
  debounceDelay?: number
  scrollBehavior?: "auto" | "smooth"
  offset?: number
  children: ReactNode
}

if (typeof window !== "undefined") {
  smoothscroll.polyfill()
}

const ScrollingProvider = ({
  debounceDelay = 50,
  scrollBehavior = "smooth",
  offset = 0,
  children,
}: Props) => {
  const [selected, setSelected] = useState("")
  const REFS: RefsRegister = useMemo(() => ({}), [])

  useEffect(() => {
    document.addEventListener("scroll", debounceScroll, true)

    const id = location.hash.replace(/^#/, "")
    const sectionRef = REFS[id]
    if (sectionRef) {
      const top = sectionRef.current?.offsetTop || 0 + offset
      window.scrollTo({ top, behavior: "auto" })
      window.history.replaceState(null, null, `#${id}`)
      setSelected(id)
    } else {
      handleScroll()
    }

    return () => {
      document.removeEventListener("scroll", debounceScroll, true)
    }
  }, [])

  const handleScroll = () => {
    const selectedSection = Object.keys(REFS).reduce(
      (acc, id) => {
        const { top } = REFS[id].current?.getBoundingClientRect() || { top: 0 }
        const differenceFromTop = Math.abs(top - offset)
        if (differenceFromTop >= acc.differenceFromTop) return acc
        return { differenceFromTop, id }
      },
      { differenceFromTop: 9999, id: "" }
    )
    if (selected !== selectedSection.id) {
      window.history.replaceState(null, null, `#${selectedSection.id}`)
      setSelected(selectedSection.id)
    }
  }

  const debounceScroll = debounce(handleScroll, debounceDelay)

  const registerRef = (id: string) => {
    const ref = createRef<HTMLElement | null>()
    REFS[id] = ref
    return ref
  }

  const scrollTo = (section: string) => {
    const sectionRef = REFS[section]
    if (!sectionRef) return console.warn("Section ID not recognized!")
    const top = (sectionRef.current?.offsetTop || 0) + offset
    window.history.replaceState(null, null, section ? `#${section}` : "")
    setSelected(section)
    window.scrollTo({ top, behavior: scrollBehavior })
  }

  const value = useMemo(
    () => ({ registerRef, scrollTo, refs: REFS, selected }),
    [selected, REFS]
  )
  return <Provider value={value}>{children}</Provider>
}

export default ScrollingProvider
